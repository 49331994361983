import * as React from 'react'
import styles from '../styles/header.module.css'

 const Header: React.FC = () => (
    
   <header>
      <script data-ad-client="ca-pub-4276664406118198" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      <nav className={styles.headerNav}>
       <a className={styles.pageLink} href="/">Home</a>  
       <a className={styles.pageLink}  href="/about">About</a>  
       <a className={styles.pageLink}  href="/tags/easy">Easy</a>  
       <a className={styles.pageLink}  href="/tags/healthy">Healthy</a>  
       <a className={styles.pageLink}  href="/tags/sweet">Sweet</a>  
       <a className={styles.pageLink}  href="/tags/weight-loss">Weight-loss</a>
       <a className={styles.pageLink}  href="/tags/smoothies">Smoothies</a>
      </nav>
   </header>
)

export default Header
