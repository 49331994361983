import * as React from 'react'

import styles  from '../styles/footer.module.css'

const Footer: React.FC = () => (

    <footer className={styles.footer}>
      <nav className={styles.footerNav}>
       <a className={styles.footerA} href="/privacy">Privacy Policy &amp; Cookies </a>  
       <a className={styles.footerB}  href="/FAQ">FAQ</a>  
      </nav>
    </footer>

)
  
export default Footer